




















import { Component } from 'vue-property-decorator';
import PromisePool from '@supercharge/promise-pool';
import SeqSendBase from './SeqSendBase.vue';
import { IUserProfile, IUserService } from '@/interfaces';
import { IGetItemsResponse } from '@/interfaces/api';
import { ISeqSendStep, ISeqSendStepCreate, ISeqSend } from '@/interfaces/seq_sends';
import {
  dispatchGetSeqSendItem,
  dispatchGetSeqSendSteps,
  dispatchUpdateSeqSend,
  dispatchDeleteSeqSendStep,
  dispatchSeqSendStepOrderChange,
  dispatchCreateSeqSendStep,
  dispatchUpdateSeqSendStep,
} from '@/store/main/accessors/dispatch';
import Stepper from '@/components/seq_sends/Stepper.vue';
import { EventBus } from '@/components/seq_sends/EventBus';

@Component({
  components: { Stepper },
})
export default class SeqSendsEdit extends SeqSendBase {
  public seqSend = {} as ISeqSend;
  public isOrderChanged: boolean = false;
  public isSeqStepAdded: boolean = false;
  public originalData = {} as { seqSend: ISeqSend; toColumn: ISeqSendStep[] };
  public removedStepsIds: Set<number> = new Set();
  public updatedStepsIds: Set<number> = new Set();
  public newSeqSteps: ISeqSendStepCreate[] = [];

  public async mounted() {
    const user: IUserProfile | null = await this.getUserProfile();
    if (!!user) {
      const { id } = this.$route.params;
      const seqSend: ISeqSend = await this.getSeqSend(Number(id));
      const seqSendSteps: IGetItemsResponse<ISeqSendStep> = await this.getSeqSendSteps(Number(id));

      this.copyData(seqSend, seqSendSteps);
      this.seqSendData(seqSend);
      this.toColumnServices(seqSendSteps);
      this.fromColumnServices(user);
    }
  }

  public seqSendData(seqSend: ISeqSend) {
    this.seqSend = { ...seqSend };
  }

  public fromColumnServices(user: IUserProfile) {
    const selectedServices: string[] = this.toColumn.services.map((s: ISeqSendStep) => {
      return s.service;
    });
    const filtered = user.services.filter((s) => !selectedServices.includes(s.service));

    this.fromColumn.services = filtered.map(
      (item: IUserService): ISeqSendStep => {
        return { service: item.service, allowedSenders: item.allowed_senders, ttl: '00:05:00', sender: '' };
      },
    );
  }

  public async toColumnServices(seqSendSteps: IGetItemsResponse<ISeqSendStep>) {
    const user: IUserProfile | null = await this.getUserProfile();
    this.toColumn.services = seqSendSteps.items.map((s: ISeqSendStep) => {
      const service = user?.services.find((i: IUserService) => {
        return i.service === s.service;
      });
      let allowedSenders: string | string[] = [];
      if (service?.allowed_senders) {
        allowedSenders = service.allowed_senders;
      }
      return {
        ...s,
        allowedSenders,
        ttl: this.ttlIntoTime(s.ttl),
      };
    });
  }

  public copyData(seqSend: ISeqSend, toColumn: IGetItemsResponse<ISeqSendStep>) {
    this.originalData = Object.freeze({
      seqSend,
      toColumn: toColumn.items,
    });
  }

  public created() {
    // Init child components liseners
    EventBus.$on('newStepAdded', (isSeqStepAdded: boolean) => (this.isSeqStepAdded = isSeqStepAdded));
    EventBus.$on('orderChanged', (isChanged: boolean) => (this.isOrderChanged = isChanged));
    EventBus.$on('stepUpdated', (stepId: number) => this.updatedStepsIds.add(stepId));
    EventBus.$on('stepRemoved', (stepId: number) => this.removedStepsIds.add(stepId));
  }

  public async getSeqSend(seqSendId: number) {
    return await dispatchGetSeqSendItem(this.$store, seqSendId);
  }

  public async getSeqSendSteps(seqSendId: number) {
    return await dispatchGetSeqSendSteps(this.$store, seqSendId);
  }

  public ttlIntoTime(ttl: number | string) {
    let seconds: string | number = Math.floor(Number(ttl) % 60);
    let minutes: string | number = Math.floor((Number(ttl) / 60) % 60);
    let hours: string | number = Math.floor((Number(ttl) / (60 * 60)) % 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return `${hours}:${minutes}:${seconds}`;
  }

  public async upadateName() {
    if (this.originalData.seqSend.name !== this.seqSend.name) {
      await dispatchUpdateSeqSend(this.$store, this.seqSend);
    }
  }

  public async addSeqStep() {
    if (this.isSeqStepAdded) {
      const newSeqSteps = this.toColumn.services.filter((s: ISeqSendStep) => !s.id);

      const { results, errors } = await PromisePool.withConcurrency(10)
        .for(newSeqSteps)
        .process(async (step: ISeqSendStep) => {
          const { service, sender, ttl: time }: ISeqSendStep = step;
          const ttl = this.convertDurationToSeconds(String(time));
          const seqSendStepCreate: ISeqSendStepCreate = {
            service,
            sender,
            ttl,
          };

          const data: { id: number; body: ISeqSendStepCreate } = { id: this.seqSend.id, body: seqSendStepCreate };
          await dispatchCreateSeqSendStep(this.$store, data);
        });
    }
  }

  public async removeSeqStep() {
    if (this.removedStepsIds.size) {
      const { results, errors } = await PromisePool.withConcurrency(10)
        .for(Array.from(this.removedStepsIds))
        .process(async (stepId: number) => {
          await dispatchDeleteSeqSendStep(this.$store, { id: this.seqSend.id, stepId });
        });
    }
  }

  public async updateSeqStep() {
    if (this.updatedStepsIds.size) {
      const { results, errors } = await PromisePool.withConcurrency(10)
        .for(Array.from(this.updatedStepsIds))
        .process(async (stepId: number) => {
          const [seqStep]: ISeqSendStep[] = this.toColumn.services.filter(
            (seqStep: ISeqSendStep) => seqStep.id === stepId,
          );
          seqStep.ttl = this.convertDurationToSeconds(String(seqStep.ttl));
          await dispatchUpdateSeqSendStep(this.$store, { id: this.seqSend.id, body: seqStep });
        });
    }
  }

  public async changeOrder() {
    if (this.isOrderChanged) {
      const seqStepIds: number[] = this.toColumn.services.map((s: ISeqSendStep) => s.id!);
      await dispatchSeqSendStepOrderChange(this.$store, {
        id: this.seqSend.id,
        seqStepIds,
      });
    }
  }

  public async update() {
    try {
      await this.upadateName();
      await this.addSeqStep();
      await this.updateSeqStep();
      await this.removeSeqStep();
      await this.changeOrder();
      this.$router.push({ name: 'main-seq-sends-all' });
    } catch (e) {
      throw e;
    }
  }

  public async done() {
    await this.update();
  }
}
